import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import {
  Container,
  ElementData,
  RangeValidation,
  ElementDataWithPlaceHolder,
  ReportedValue,
  UiEvent,
} from '@flow/flow-backend-types';
import { names, useSpy } from 'services/espionage';
import {
  DateEvent,
  ButtonsEvent,
  NumericEvent,
  TextEvent,
  DropdownEvent,
  MultiSelectEvent,
  EventProps,
  InputEventProps,
  MultiSelectEventProps,
  AppendTextEvent,
  AppendTextEventProps,
  TimeEvent,
  NumericEventProps,
} from 'components';
import { UiEventData, useBoundsByEventId, useValidationByEventId } from 'stores/uiEvent';
import { getReportCollectionKey, useReporter, useReportStore } from 'stores/report';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useDistraction } from 'stores/app';
import { useVoiceStore } from 'stores/voice';
import { EventTitle } from './EventTitle';

interface RenderEventProps {
  container: Container;
  uiEvent: UiEvent;
  isMandatory?: boolean;
  onReportValueChange?: (value?: ReportedValue) => void;
  disabled?: boolean;
}

export const RenderEvent = ({ container, uiEvent, isMandatory, disabled, onReportValueChange }: RenderEventProps) => {
  const { id: containerId } = container;
  const { id: eventId, title: eventTitle } = uiEvent;
  const reportKey = getReportCollectionKey(containerId, eventId);
  const { spyClick } = useSpy();
  const { executionId } = useParams() as ExecutionRouteParams;
  const validation = useValidationByEventId(containerId, eventId);
  const bounds = useBoundsByEventId(containerId, eventId);
  const { distracting, distract, concentrate } = useDistraction(`event-edit:${eventId}`);
  const { reports, validity, boundedness } = useReportStore(['reports', 'validity', 'boundedness']);
  const { lastReport, triggerReport } = useReporter({ executionId, containerId, eventId });
  const {
    internal: { setContext },
  } = useVoiceStore(['internal']);

  const triggerFocus = () => {
    spyClick(names.Container.ManualEditEvent, { containerId, eventId });
    distract();
  };

  const triggerBlur = (value?: ReportedValue) => {
    spyClick(names.Container.ManualEditEventDone, { containerId, eventId, value });
    concentrate();
  };

  function getEventProps<T extends ElementData>(elementData: T): EventProps<T> {
    return {
      title: <EventTitle title={eventTitle} isMandatory={isMandatory} />,
      reportKey,
      elementData: elementData as T,
      lastEventReport: lastReport,
      validation,
      valid: validity[reportKey],
      disabled,
      triggerReport,
    };
  }

  function getInputEventProps<T extends ElementDataWithPlaceHolder>(elementData: T): InputEventProps<T> {
    return {
      ...getEventProps(elementData),
      isEditing: distracting,
      placeHolder: elementData?.placeHolder,
      triggerFocus,
      triggerBlur,
    };
  }

  function getMultiSelectEventProps<T extends ElementData>(elementData: T): MultiSelectEventProps<T> {
    return {
      ...getEventProps(elementData),
      reports,
    };
  }

  function getTextEventProps<T extends ElementDataWithPlaceHolder>(elementData: T): InputEventProps<T> {
    return {
      ...getInputEventProps(elementData),
      triggerFocus: () => {
        setContext({ containerId, eventDefinitionId: eventId });
        triggerFocus();
      },
    };
  }

  function getAppendTextEventProps<T extends ElementDataWithPlaceHolder>(elementData: T): AppendTextEventProps<T> {
    return {
      ...getInputEventProps(elementData),
      reports,
      triggerFocus: () => {
        setContext({ containerId, eventDefinitionId: eventId });
        triggerFocus();
      },
    };
  }

  function getNumberEventProps<T extends ElementDataWithPlaceHolder>(elementData: T): NumericEventProps<T> {
    return {
      ...getInputEventProps(elementData),
      bounds: bounds as RangeValidation,
      bounded: boundedness[reportKey],
      validation: validation as RangeValidation,
    };
  }

  useEffect(() => {
    onReportValueChange?.(lastReport?.reportedValue);
  }, [lastReport]);

  function getEventUiComponent({ type, elementData }: UiEventData) {
    switch (type) {
      case 'ButtonsEvent':
        return <ButtonsEvent {...getEventProps(elementData)} />;
      case 'DropdownEvent':
        return <DropdownEvent {...getEventProps(elementData)} />;
      case 'MultiSelectEvent':
        return <MultiSelectEvent {...getMultiSelectEventProps(elementData)} />;
      case 'DateEvent':
        return <DateEvent {...getNumberEventProps(elementData)} />;
      case 'TimeOfDayEvent':
        return <TimeEvent {...getNumberEventProps(elementData)} />;
      case 'TextEvent':
        return <TextEvent {...getTextEventProps(elementData)} />;
      case 'NumericEvent':
        return <NumericEvent {...getNumberEventProps(elementData)} />;
      case 'AppendTextEvent':
        return <AppendTextEvent {...getAppendTextEventProps(elementData)} />;
      default:
        return null;
    }
  }

  return useMemo(() => getEventUiComponent(uiEvent as UiEventData), [reportKey, lastReport, disabled, distracting]);
};
