import { PendingAction } from '@flow/flow-backend-types';
import { generateId } from '@aiola/frontend';
import { PendingActionPayload } from './db.types';

export const createPendingAction = (action: PendingActionPayload): PendingAction => ({
  id: generateId(),
  type: action.type,
  payload: action.payload,
  timestamp: Date.now(),
  retries: 0,
});
