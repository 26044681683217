import { UiEvent } from '@flow/flow-backend-types';
import { useEffect } from 'react';
import { useDistraction } from 'stores/app';
import { useVoiceStore } from 'stores/voice';

export function useContextualEventActions(opened: boolean, uiEvent?: UiEvent) {
  const {
    distracting: editing,
    concentrate: stopEditing,
    distract: startEditing,
  } = useDistraction(`event-edit:${uiEvent?.id}`);
  const { changeVoiceState, reportingContext } = useVoiceStore([
    'changeVoiceState',
    'currentVoiceStatus',
    'reportingContext',
  ]);

  const startRecording = () => changeVoiceState('open', reportingContext);
  const stopRecording = () => changeVoiceState('close');

  useEffect(() => {
    if (opened && !editing) startRecording();
    if (!opened) stopRecording();
  }, [editing, opened]);

  return {
    editing,
    startEditing,
    stopEditing,
    startRecording,
    stopRecording,
  };
}
