import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { noop } from 'utils';

interface DrawerState {
  onClose: () => void;
}

const DrawerContext = createContext<DrawerState>({ onClose: noop });

interface DrawerProviderProps extends DrawerState, PropsWithChildren {}

export const DrawerProvider = ({ children, onClose }: DrawerProviderProps) => {
  const value = useMemo<DrawerState>(() => ({ onClose }), [onClose]);

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};

export const useDrawerContext = () => useContext(DrawerContext);
