import { Flow, UserIdentity } from '@flow/flow-backend-types';
import dayjs from 'dayjs';
import { generateId } from '@aiola/frontend';
import { Execution } from './flow.types';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
export const getOneDayAgo = () => Date.now() - ONE_DAY_MS;

export const createExecution = (
  {
    flowRef,
    preInspectionMetadata,
    uniqueIdentifier,
  }: Pick<Execution, 'flowRef' | 'preInspectionMetadata' | 'uniqueIdentifier'>,
  userIdentity: UserIdentity,
): Execution => ({
  id: generateId(),
  flowRef,
  preInspectionMetadata,
  uniqueIdentifier,
  status: 'inProgress',
  createdBy: userIdentity,
  joinedUsers: [userIdentity],
  workedOn: [userIdentity],
  createdAt: Date.now(),
  originalTZName: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const getTimeAgo = (time: string) => Date.now() - parseInt(time, 10);

export const sortByExecutionCreationDate = (executions: Execution[]) =>
  executions.sort((a, b) => b.createdAt - a.createdAt);

export const sortByExecutionFinishDate = (executions: Execution[]) =>
  executions.sort((a, b) => {
    if (a.finishedAt && b.finishedAt) return b.finishedAt - a.finishedAt;
    if (a.finishedAt) return -1;
    if (b.finishedAt) return 1;
    return 0;
  });

export const getIdsAndVersions = (flows: Flow[], executions: Execution[]) => {
  const flowIdsAndVersions = flows.map(({ id, activeVersion }) => `${id}:${activeVersion}`);
  const executionIdsAndVersions = executions.map(({ id, flowRef }) => `${flowRef.id}:${flowRef.version}:${id}`);
  return [...new Set([...flowIdsAndVersions, ...executionIdsAndVersions])];
};

export const filterMyExecutions = (executions: Execution[], userId: string) =>
  executions.filter(
    ({ joinedUsers, status }) =>
      status === 'inProgress' && joinedUsers.length === 1 && joinedUsers[0].userId === userId,
  );

export const filterJoinableExecutions = (executions: Execution[], flows: Flow[], userId: string): Execution[] => {
  const flowMap = new Map(flows.map((flow) => [flow.id, flow.maxInspectors]));

  return executions.filter(({ flowRef, joinedUsers, status }) => {
    if (status !== 'inProgress') return false;

    const maxInspectors = flowMap.get(flowRef.id);

    if (maxInspectors === undefined) return false;
    if (maxInspectors === 0) return true;

    return joinedUsers.length < maxInspectors || joinedUsers.some((user) => user.userId === userId);
  });
};

export const generateUniqueIdentifier = (user: UserIdentity, dateFormat: string) => {
  const formattedDate = dayjs().format(`hh:mm:ssA, ${dateFormat}`);
  return `${user.givenName} ${user.familyName} (${formattedDate})`;
};

export const joinUserToExecution = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  workedOn: execution.workedOn.includes(user) ? execution.workedOn : [...execution.workedOn, user],
  joinedUsers: [...execution.joinedUsers, user],
});

export const markExecutionAsReviewed = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  status: 'inReview',
  reviewedBy: user,
  reviewedAt: Date.now(),
});

export const markExecutionAsInProgress = (execution: Execution): Execution => ({
  ...execution,
  reviewedBy: undefined,
  reviewedAt: undefined,
  status: 'inProgress',
});

export const markExecutionAsFinished = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  status: 'pending',
  postInspectionMetadata: [],
  finishedBy: user,
  finishedAt: Date.now(),
});

export const markExecutionAsCanceled = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  status: 'cancelled',
  finishedBy: user,
  finishedAt: Date.now(),
});
