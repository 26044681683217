import { GenerationMethod, ReportValueType, UiEvent } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { useCurrentUser } from 'stores/auth';
import { useFlowStore } from 'stores/flow';
import { getReportCollectionKey, useReportStore } from 'stores/report';
import { pullLastReport } from 'stores/report/report.utils';
import { appendTextReports, useUiEventStore } from 'stores/uiEvent';
import { useVoiceStore } from 'stores/voice';
import { exists } from 'utils';

export function useContextualUiEvent(): {
  containerId?: string;
  uiEvent?: UiEvent;
  value?: string;
  editValue?: (value: string) => void;
  resetContext?: () => void;
} {
  const { reportingContext, internal } = useVoiceStore(['reportingContext', 'internal']);
  const { uiEvents } = useUiEventStore(['uiEvents']);
  const { reports, send } = useReportStore(['reports', 'send']);
  const currentUser = useCurrentUser();
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);

  return useMemo(() => {
    if (!exists(reportingContext)) return {};
    const { containerId, eventDefinitionId } = reportingContext;
    const uiEvent = uiEvents[eventDefinitionId];
    const reportKey = getReportCollectionKey(containerId, eventDefinitionId);
    return {
      containerId,
      uiEvent,
      value:
        uiEvent?.type === 'AppendTextEvent'
          ? appendTextReports(reports[reportKey])
          : (pullLastReport(reports, containerId, eventDefinitionId)?.reportedValue as string),
      resetContext: () => internal.setContext(),
      editValue: (value: string) =>
        send([
          {
            containerId,
            eventDefId: eventDefinitionId,
            userId: currentUser!.userId,
            flowExecutionId: currentExecutionId,
            generationMethod: GenerationMethod.USER_ACTION,
            reportedValue: value,
            reportedValueType: ReportValueType.STRING,
          },
        ]),
    };
  }, [reportingContext, uiEvents, reports]);
}
