import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestAudioPermissions,
} from 'mic-check/lib/requestMediaPermissions';
import { config } from 'services/config';
import { AUDIO_PARAMS } from 'services/voice/recorder/recorder.const';
import { AudioMetadata, SocketUrl } from 'services/voice/audioSocket';
import { generateId, TimeTurner } from '@aiola/frontend';
import { AudioSocketUrlParams, MicrophonePermission, VoiceErrorReason } from './voice.types';

const { AUDIO_ENCODING, SAMPLE_RATE } = AUDIO_PARAMS;

export async function getMicrophonePermission(): Promise<MicrophonePermission> {
  try {
    await requestAudioPermissions();
    console.info('Microphone permissions %cgranted', 'color: skyblue; font-weight: bold;');
    return { permitted: true };
  } catch (err) {
    const { type, message } = err as MediaPermissionsError;

    const errorMessage: VoiceErrorReason =
      type === MediaPermissionsErrorType.UserPermissionDenied ? 'userPermissionDeny' : 'systemPermissionDeny';

    console.error('Microphone permissions %cdenied', 'color: red; font-weight: bold;', message);

    return { permitted: false, error: errorMessage };
  }
}

export function getWebSocketUrl(params: AudioSocketUrlParams): SocketUrl {
  const uri = config.audioWsHost ?? `${window.location.origin.replace(/^http/, 'ws')}`;
  const path = config.audioPath;

  const metadata: AudioMetadata = {
    audioEncoding: AUDIO_ENCODING,
    languageCode: params.languageCode,
    sampleRate: SAMPLE_RATE,
    userId: params.userId,
    streamId: generateId(),
    flowId: params.flowId,
    executionId: params.executionId,
    version: params.version,
    voiceMode: params.voiceMode,
    flowSessionId: params.flowSessionId,
    frameFormat: params.frameFormat ?? 'debug',
    tz: TimeTurner.getLocalTimezone(),
  };

  return { uri, path, query: metadata };
}
